import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  imports: [RouterModule],
  selector: 'ez-shop-layout-underconstruction',
  template: `
    <!-- Not using bootstrap classes as MDBootstrap not compatible with Angular 19 for now -->
    <div  style="
            display: flex;
            justify-content: center;
            width: 100vw;
            height: 100vh;
            background-image: url('images/underConstruction.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            font-family: 'Arimo', sans-serif;
            color: #395781;">
      <div style="
              background-color: rgba(255, 255, 255, 0.9);
              position: fixed;
              top: 12.5vh;
              min-width: 50vw;
              min-height: 50vh;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              border: 5px solid #395781;
              border-radius: 10px;
              padding: 15px;
              margin: 15px;">
        <div style="
                width: 25vw;
                min-width: 220px;
                max-width: 300px;
                height: 25vh;
                min-height: 120px;
                max-height: 200px;
                background-image: url('images/logo.png');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;">
        </div>
        <h3 style="text-align: center">Your Complete ICT Partner</h3>
        <h5 style="text-align: center">
          Websites&nbsp;/&nbsp;Software&nbsp;/&nbsp;Support&nbsp;/
          Hardware&nbsp;/&nbsp;Networking
        </h5>
        <h2 style="text-align: center">UNDER DEVELOPMENT</h2>
      </div>
    </div>
  `
})
export class LayoutUnderConstructionComponent {
  constructor() {
  }
}
