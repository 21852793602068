import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  imports: [RouterModule],
  selector: 'ez-shop-layout-authentication',
  template: `
    <header>AUTHENTICATION Header</header>
    <router-outlet></router-outlet>
    <footer>AUTHENTICATION Footer</footer>
  `
})
export class LayoutAuthenticationComponent {
  constructor() {
  }
}
