import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { coreRoutes } from './core.routes';
import {
  provideClientHydration,
  withEventReplay,
} from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withFetch } from '@angular/common/http';

export const coreConfig: ApplicationConfig = {
  providers: [
    { provide: 'APP_ENVIRONMENT', useValue: environment },
    provideClientHydration(withEventReplay()),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(coreRoutes),
    provideAnimations(),
    provideHttpClient(withFetch())
  ],
};
