import { Injectable } from '@angular/core';

@Injectable()
export class TranslateService {
}

  /*import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, filter, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TranslateService {
  private _readySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _languageSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private _translationsSubject: BehaviorSubject<Array<string> | null> = new BehaviorSubject<Array<string> | null>(null);

  get language$(): Observable<string> {
    return combineLatest([
      this._readySubject,
      this._languageSubject
    ]).pipe(
      filter(([ready, _]) => ready),
      map(([_, language]) => language!)
    );
  }

  get languages$(): Observable<Array<LanguageModel>> {
    return combineLatest([
      this._readySubject,
      this._languagesSubject
    ]).pipe(
      filter(([ready, _]) => ready),
      map(([_, languages]) => languages!)
    )
  }

  get translations$(): Observable<Array<string>> {
    return combineLatest([
      this._readySubject,
      this._translationsSubject
    ]).pipe(
      filter(([ready, _]) => ready),
      map(([_, translations]) => translations!)
    );
  }

  constructor() {
  }
}
*/
