import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  imports: [RouterModule],
  selector: 'ez-shop-layout-maintenance',
  template: `
    <header>MAINTENANCE Header</header>
    <router-outlet></router-outlet>
    <footer>MAINTENANCE Footer</footer>
  `
})
export class LayoutMaintenanceComponent {
  constructor() {
    //
  }
}
