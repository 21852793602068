import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthenticationService, CoreService } from '@ez-web/services';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { WaitComponent } from '@ez-web/components';

@Component({
  imports: [CommonModule, RouterModule, WaitComponent],
  selector: 'ez-core',
  template: `
    <div *ngIf="isLoaded; else loader" [@fadeIn]="isLoaded ? 'visible' : 'hidden'">
      <router-outlet />
    </div>
    <ng-template #loader>
      <ez-wait [showMessage]="showMessage" />
    </ng-template>
  `,
  animations: [
    trigger('fadeIn', [
      transition('hidden => visible', [
        style({ opacity: 0 }),
        animate('1s ease-in-out', style({ opacity: 1 }))
      ])
    ])
  ],
})
export class CoreComponent {
  protected isLoaded = false;
  protected showMessage = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private authentication: AuthenticationService,
    private core: CoreService
  ) {
    if (isPlatformBrowser(this.platformId))
      window.onload = () => this.isLoaded = true;

    setTimeout(() => this.showMessage = !this.isLoaded, 5000);
  }
}
