import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { IEnvironment } from '@ez-web/tokens'
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  constructor(
    @Inject( 'APP_ENVIRONMENT') private environment: IEnvironment,
    private router: Router
  ) {
    console.log ('ENV: ', environment)
    switch (environment.environment) {
      case 'underConstruction': {
        this.router.navigate(['/underConstruction']);
        return;
      }
      case 'maintenance': {
        this.router.navigate(['/maintenance']);
        return;
      }
    }

    // If backend does not answer, route to notResponding
    // If not authentificated, route to authentication
    // If route does not exist, route to notFound
    // If all good, do nothing, standard routes will be used
  }
}
