import { Route } from '@angular/router';
import { LayoutAuthenticationComponent } from '../layouts/authentication/layoutAuthentication.component';
import { LayoutMainComponent } from '../layouts/main/./layoutMain.component';
import { LayoutMaintenanceComponent } from '../layouts/maintenance/layoutMaintenance.component';
import { LayoutNotRespondingComponent } from '../layouts/notResponding/layoutNotResponding.component';
import { LayoutUnderConstructionComponent } from '../layouts/underConstruction/layoutUnderConstruction.component';

export const coreRoutes: Route[] = [
  {
    path: 'authentication',
    component: LayoutAuthenticationComponent,
    children: [
    ]
  },
  {
    path: 'maintenance',
    component: LayoutMaintenanceComponent,
    children: [
    ]
  },
  {
    path: 'notResponding',
    component: LayoutNotRespondingComponent,
    children: [
    ]
  },
  {
    path: 'underConstruction',
    component: LayoutUnderConstructionComponent,
    children: [
    ]
  },
  {
    path: '',
    component: LayoutMainComponent,
    children: [
    ]
  }
];
