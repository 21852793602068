import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  imports: [ CommonModule ],
  selector: 'ez-wait',
  template: `
    <div class="main-container">
      <div class="circle-container">
        <div class="circle-border"></div>
        <div class="circle-content"></div>
      </div>
      <div class="message-container"
           [ngClass]="{ 'invisible': !showMessage }"
           [innerHTML]="innerHtml">
      </div>
    </div>
  `,
  styles: [`
    .main-container {
      width: 100vw;
      height: 66vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .circle-container {
      position: relative;
      width: 200px;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .circle-content {
      width: 90%;
      height: 90%;
      background-color: white;
      background-image: url('/images/logo-wait.png');
      background-position: center;
      border-radius: 50%;
      position: absolute;
    }

    .circle-border {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: conic-gradient(
        transparent 90deg,
        #a5b3c6 180deg,
        #395781 270deg,
        #7c90ac 360deg
      );
      animation: spin 2s linear infinite;
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    .message-container {
      margin-top: 20px;
      &.invisible {
        visibility: hidden;
      }
    }
  `]
})
export class WaitComponent {
  @Input() innerHtml: string = 'Server is not responding';
  @Input() showMessage = true;
}
