import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, catchError, Observable, tap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IEnvironment } from '@ez-web/tokens';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

interface IAccessToken {
  authenticationTime: string; // Corresponds to Claims.AuthenticationTime
  nonce: string;              // Corresponds to Claims.Nonce
  familyName: string;         // Corresponds to Claims.FamilyName
  name: string;               // Corresponds to Claims.Name
  email: string;              // Corresponds to Claims.Email
  scope: string;              // Corresponds to Claims.Scope
  sessionUid: string;         // Corresponds to "session_uid"
}

export interface IAuthModel {
  access_token: string;
  token_type: string;
  received_at?: number;
  expires_in: number;
  refresh_token: string;
  scope: string;
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private userSubject: BehaviorSubject<IAuthModel | null> = new BehaviorSubject<IAuthModel | null>(null);
  private impersonationSubject: BehaviorSubject<IAuthModel | null> = new BehaviorSubject<IAuthModel | null>(null);

  constructor(
    @Inject( 'APP_ENVIRONMENT') private environment: IEnvironment,
    @Inject(PLATFORM_ID) private platformId: any,
    private http: HttpClient
  ) {
    this.loadTokensFromStorage();
  }

  get user (): IAuthModel | null { return this.userSubject.value; }
  get impersonation (): IAuthModel | null { return this.impersonationSubject.value; }

  private loadTokensFromStorage(): void {
    if (isPlatformServer(this.platformId)) return;
    const currentTime = Math.floor(Date.now() / 1000);
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const authModel: IAuthModel = JSON.parse(storedUser);
      if (authModel.received_at! + authModel.expires_in > currentTime) {
        this.userSubject.next(authModel);
      } else {
        localStorage.removeItem('user');
      }
    }
    const storedImpersonation = localStorage.getItem('impersonation');
    if (storedImpersonation) {
      const authModel: IAuthModel = JSON.parse(storedImpersonation);
      if (authModel.received_at! + authModel.expires_in > currentTime) {
        this.impersonationSubject.next(authModel);
      } else {
        localStorage.removeItem('impersonation');
      }
    }
  }

  private storeUser(authModel: IAuthModel): void {
    authModel.received_at ??= Math.floor(Date.now() / 1000);
    localStorage.setItem('user', JSON.stringify(authModel));
    this.userSubject.next(authModel);
  }

  private storeImpersonation(authModel: IAuthModel): void {
    authModel.received_at ??= Math.floor(Date.now() / 1000);
    localStorage.setItem('impersonation', JSON.stringify(authModel));
    this.impersonationSubject.next(authModel);
  }

  public login(username: string, password: string): Observable<IAuthModel> {
    const body = new URLSearchParams();
    body.set('grant_type', 'password');
    body.set('username', username);
    body.set('password', password);
    body.set('client_id', this.environment.client_id);
    body.set('client_secret', this.environment.client_secret);

    return this.http.post<IAuthModel>(this.environment.ezauth, body.toString(), {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    }).pipe(
      tap(response => this.storeUser(response)),
      catchError(error => { throw new Error('Login failed: ' + error.message); })
    );
  }

  refresh(): Observable<IAuthModel> {
    if (!this.user?.refresh_token) {
      throw new Error('No refresh token available');
    }
    const body = new URLSearchParams();
    body.set('grant_type', 'refresh_token');
    body.set('refresh_token', this.user.refresh_token);
    body.set('client_id', this.environment.client_id);
    body.set('client_secret', this.environment.client_secret);

    return this.http.post<IAuthModel>(this.environment.ezauth, body.toString(), {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    }).pipe(
      tap(response => this.storeUser(response)),
      catchError(error => { throw new Error('Token refresh failed: ' + error.message); })
    );
  }

  public logout(): void {
    localStorage.removeItem('user');
    localStorage.removeItem('impersonate');
    this.impersonationSubject.next(null);
    this.userSubject.next(null);
  }

  public logAs(uid: string): Observable<IAuthModel> {
    /*const body = new URLSearchParams();
    body.set('grant_type', 'password');
    body.set('username', username);
    body.set('password', password);
    body.set('client_id', this.environment.client_id);
    body.set('client_secret', this.environment.client_secret);

    return this.http.post<IAuthModel>(this.environment.ezauth, body.toString(), {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    }).pipe(
      tap(response => this.storeUser(response)),
      catchError(error => { throw new Error('Login failed: ' + error.message); })
    ); */
    return new Observable<IAuthModel>();
  }

  refreshAs(): Observable<IAuthModel> {
    if (!this.impersonation?.refresh_token) {
      throw new Error('No refresh token available');
    }
    const body = new URLSearchParams();
    body.set('grant_type', 'refresh_token');
    body.set('refresh_token', this.impersonation.refresh_token);
    body.set('client_id', this.environment.client_id);
    body.set('client_secret', this.environment.client_secret);

    return this.http.post<IAuthModel>(this.environment.ezauth, body.toString(), {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    }).pipe(
      tap(response => this.storeUser(response)),
      catchError(error => { throw new Error('Token refresh failed: ' + error.message); })
    );
  }

  public logoutAs(): void {
    localStorage.removeItem('impersonate');
    this.impersonationSubject.next(null);
  }
}
