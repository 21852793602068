import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  imports: [RouterModule],
  selector: 'ez-shop-layout-notresponding',
  template: `
    <header>NOT RESPONDING Header</header>
    <router-outlet></router-outlet>
    <footer>NOT RESPONDING Footer</footer>
  `
})
export class LayoutNotRespondingComponent {
  constructor() {
  }
}
